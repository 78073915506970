.youtubeContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

/*.youtubeContainer iframe {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*}*/
